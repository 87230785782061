<template>
<div>
    <b-row>

        <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <b-card no-body>
                <b-card-header class="pb-50">
                    <h5>
                        Filtreleme
                    </h5>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col cols="12" md="12" class="mb-md-0 mb-2">
                            <label>Sınıf/Ders</label>
                            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="option => option.class.name+' / '+option.lesson.name" :reduce="val => val.id" />
                        </b-col>

                    </b-row>
                    <b-row>
                        <b-col cols="12" md="12" class="mb-md-0 mb-2">
                            <label>Alt Ders</label>
                            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedChildLesson" :options="childLessons" class="w-100" :reduce="val => val.id" label="name" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="12" class="mb-md-0 mb-2">
                            <label>Konu</label>
                            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedTopic" :options="topics" class="w-100" :reduce="val => val.id" label="name" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="12" class="mb-md-0 mb-2">
                            <label>Video Grup</label>
                            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedVideoGroup" :options="videoGroups" class="w-100" :reduce="val => val.id" label="name" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="12" class="mb-md-0 mb-2">
                            <label>Test</label>
                            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedTest" :options="tests" class="w-100" :reduce="val => val" label="title" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="12" class="mb-md-0 mb-2 mt-2">
                            <b-button variant="success" @click="getTestData()" block>FİLTRELE</b-button>
                        </b-col>
                        <b-col cols="12" md="12" class="mb-md-0 mb-2 mt-2" v-if="selectedTest!=null">
                            <b-button variant="primary" @click="getExamResult()" block>İNDİR</b-button>
                        </b-col>
                    </b-row>
                    <b-overlay :show="loading" no-wrap>
                    </b-overlay>
                </b-card-body>
            </b-card>
        </b-col>
        <b-col cols="12" md="8" class="mb-md-0 mb-2">
            <b-card no-body>
                <b-table :items="users" responsive :fields="fields" class="mb-0" :filter="filterText">
                    <!-- Id -->
                    <template #cell(id)="data">
                        <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
                    </template>
                    <template #cell(question)="data">
                        <a target="_blank" :href="data.item.questionFile.url" class="font-weight-bolder mb-12" style="width:100%">Aç</a>
                    </template>
                    <template #cell(solution)="data">
                        <b-button variant="primary" @click="getVideoUrl(data.item.solutionFile.url)" class="font-weight-bolder mb-12" style="width:100%">Aç</b-button>
                    </template>
                    <template #cell(answer)="data">
                        <span>
                            {{
                                data.item.answers.filter(x=>x.isTrue==true).length>0?
                                data.item.answers.filter((x) => x.isTrue == true)[0].text:'CEVAP SEÇİLMEDİ'
                            }}
                        </span>
                    </template>
                </b-table>
            </b-card>
        </b-col>
    </b-row>
    <b-modal v-model="showVideo" centered hide-footer hide-header size="lg" modal-class="center">
        <vimeo-player ref="player" player-width="720" :video-url="videoUrl" />
    </b-modal>
</div>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import XLSX from 'xlsx';
export default {
    components: {
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        vSelect,
        BProgress,
        BOverlay
    },
    data() {
        return {
            loading: false,
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'question', label: 'Soru Resmi' },
                { key: 'answer', label: 'Doğru Cevap' },
                { key: 'gains', label: 'Kazanım' },
                { key: 'solution', label: 'Çözüm Videosu' },
            ],
            selectedLesson: null,
            selectedChildLesson: null,
            selectedTopic: null,
            selectedVideoGroup: null,
            selectedTest: null,
            users: [],
            lessons: [],
            childClass: [],
            childLessons: [],
            videoGroups: [],
            topics: [],
            tests: [],
            filterText: '',
            filterRequest: {
                lessnId: '',
                childClassId: '',
                childLessonId: '',
                status: 5
            },
            ort: {
                totalOrt: 0,
                correct: 0,
                wrong: 0,
                empty: 0,
                point: 0
            },
            showVideo: false,
            videoUrl: ''
        }
    },
    created() {
        this.getLesson();
    },
    watch: {
        selectedLesson: function (val) {
            this.getChildLesson();
        },
        selectedChildLesson: function () {
            this.getTopics();
        },
        selectedTopic: function () {
            this.getVideoGroup();
        },
        selectedVideoGroup: function () {
            this.getTests();
        }
    },
    methods: {
        getVideoUrl(item) {
            this.videoUrl = item;
            this.showVideo = true;
        },
        async getLesson() {
            var lesson = await this.$http.get("Teacher/Lessons");
            this.lessons = lesson.data.data;
        },

        async getChildLesson() {
            this.filterRequest.childLessonId = "";
            var data = this.lessons.filter(x => x.id == this.selectedLesson);
            if (data.length > 0) {
                data = data[0];
                var childLesson = await this.$http.get("Teacher/ChildLessons/" + data.lesson.id + "/" + data.class.id);
                this.childLessons = childLesson.data.data;
            }
        },

        async getTopics() {
            var topics = await this.$http.get("Teacher/Topics/" + this.selectedChildLesson);
            this.topics = topics.data.data;
        },

        async getVideoGroup() {
            var videoGroups = await this.$http.get("Teacher/VideoGroup/" + this.selectedTopic);
            this.videoGroups = videoGroups.data.data;
        },

        async getTests() {
            var tests = await this.$http.get("Teacher/Test/" + this.selectedVideoGroup);
            this.tests = tests.data.data;
        },

        async getTestData() {
            if (this.selectedTest != null) {
                var lesson = await this.$http.get("Teacher/TestQuestion/" + this.selectedTest.id);
                this.users = lesson.data.data;
                this.loading = false;
            }
        },

        async getExamResult() {
            let excelItems = [];
            var index = 1;
            this.users.forEach(x => {
                //console.log(x);
                excelItems.push({
                    "Soru Numarası": index,
                    "Cevap": x.answers.filter((x) => x.isTrue == true)[0].text,
                    "Resim": x.questionFile.url,
                    "Video": x.solutionFile.url,
                    "Kazanım":JSON.stringify(x.gains)
                });
                index++;
            })
            let data = XLSX.utils.json_to_sheet(excelItems);
            data["!cols"] = [{ width: 10 }, { width: 10 }, { width: 80 } , { width: 30 } , { width: 60 } ]; ;
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            await XLSX.writeFile(wb, this.selectedTest.title + ".xlsx");
        }

    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style><style scoped>
iframe {
    width: 100%;
    height: 100%;
}
</style>
